/* tslint:disable */
/* eslint-disable */
/**
 * Solsteads API
 * The API that supports solsteads.com
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddGuestApprovalRequestBody
 */
export interface AddGuestApprovalRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AddGuestApprovalRequestBody
     */
    'guestTokenAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddGuestApprovalRequestBody
     */
    'guestWalletAddress'?: string;
}
/**
 * 
 * @export
 * @interface AddGuestRequestRequestBody
 */
export interface AddGuestRequestRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AddGuestRequestRequestBody
     */
    'solsteadTokenAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddGuestRequestRequestBody
     */
    'solsteadWalletAddress'?: string;
}
/**
 * 
 * @export
 * @interface AddWalletParameters
 */
export interface AddWalletParameters {
    /**
     * 
     * @type {Wallet}
     * @memberof AddWalletParameters
     */
    'wallet'?: Wallet;
    /**
     * 
     * @type {string}
     * @memberof AddWalletParameters
     */
    'signature'?: string;
}
/**
 * 
 * @export
 * @interface Attribute
 */
export interface Attribute {
    /**
     * 
     * @type {string}
     * @memberof Attribute
     */
    'trait_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attribute
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface Biome
 */
export interface Biome {
    /**
     * 
     * @type {string}
     * @memberof Biome
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface Collection
 */
export interface Collection {
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    'family': string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CommunityMember
 */
export interface CommunityMember {
    /**
     * 
     * @type {Token}
     * @memberof CommunityMember
     */
    'communityToken'?: Token;
    /**
     * 
     * @type {string}
     * @memberof CommunityMember
     */
    'primarySolsteadId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommunityMember
     */
    'secondarySolsteadIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Creator
 */
export interface Creator {
    /**
     * 
     * @type {string}
     * @memberof Creator
     */
    'address'?: string;
    /**
     * 
     * @type {number}
     * @memberof Creator
     */
    'share'?: number;
}
/**
 * 
 * @export
 * @interface DeleteGuestApprovalRequestBody
 */
export interface DeleteGuestApprovalRequestBody {
    /**
     * 
     * @type {string}
     * @memberof DeleteGuestApprovalRequestBody
     */
    'guestTokenAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteGuestApprovalRequestBody
     */
    'guestWalletAddress'?: string;
}
/**
 * 
 * @export
 * @interface Frame
 */
export interface Frame {
    /**
     * 
     * @type {string}
     * @memberof Frame
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface Gallery
 */
export interface Gallery {
    /**
     * 
     * @type {Frame}
     * @memberof Gallery
     */
    'frame'?: Frame;
    /**
     * 
     * @type {Array<TokenIdentifiers>}
     * @memberof Gallery
     */
    'order'?: Array<TokenIdentifiers>;
    /**
     * 
     * @type {Array<TokenIdentifiers>}
     * @memberof Gallery
     */
    'filter'?: Array<TokenIdentifiers>;
    /**
     * 
     * @type {Array<Token>}
     * @memberof Gallery
     */
    'tokens'?: Array<Token>;
}
/**
 * 
 * @export
 * @interface GetSolsteadHoliday2021EntryResponse
 */
export interface GetSolsteadHoliday2021EntryResponse {
    /**
     * 
     * @type {string}
     * @memberof GetSolsteadHoliday2021EntryResponse
     */
    'solsteadId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSolsteadHoliday2021EntryResponse
     */
    'walletAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSolsteadHoliday2021EntryResponse
     */
    'image'?: string;
}
/**
 * 
 * @export
 * @interface GetSolsteadResponse
 */
export interface GetSolsteadResponse {
    /**
     * 
     * @type {Solstead}
     * @memberof GetSolsteadResponse
     */
    'solstead'?: Solstead;
}
/**
 * 
 * @export
 * @interface GetTokenMetadataResponse
 */
export interface GetTokenMetadataResponse {
    /**
     * 
     * @type {Metadata}
     * @memberof GetTokenMetadataResponse
     */
    'metadata'?: Metadata;
}
/**
 * 
 * @export
 * @interface GetTokenWalletResponse
 */
export interface GetTokenWalletResponse {
    /**
     * 
     * @type {string}
     * @memberof GetTokenWalletResponse
     */
    'address'?: string;
}
/**
 * 
 * @export
 * @interface GetUserResponse
 */
export interface GetUserResponse {
    /**
     * 
     * @type {User}
     * @memberof GetUserResponse
     */
    'user'?: User;
}
/**
 * 
 * @export
 * @interface GetWalletCollectionsResponse
 */
export interface GetWalletCollectionsResponse {
    /**
     * 
     * @type {Array<Collection>}
     * @memberof GetWalletCollectionsResponse
     */
    'collections'?: Array<Collection>;
}
/**
 * 
 * @export
 * @interface GetWalletTokensResponse
 */
export interface GetWalletTokensResponse {
    /**
     * 
     * @type {Array<Token>}
     * @memberof GetWalletTokensResponse
     */
    'tokens'?: Array<Token>;
}
/**
 * 
 * @export
 * @interface GetWalletTwitterHandleResponse
 */
export interface GetWalletTwitterHandleResponse {
    /**
     * 
     * @type {string}
     * @memberof GetWalletTwitterHandleResponse
     */
    'twitterHandle'?: string;
}
/**
 * 
 * @export
 * @interface Guest
 */
export interface Guest {
    /**
     * 
     * @type {Token}
     * @memberof Guest
     */
    'guestToken': Token;
    /**
     * 
     * @type {Wallet}
     * @memberof Guest
     */
    'guestWallet': Wallet;
    /**
     * 
     * @type {string}
     * @memberof Guest
     */
    'guestTwitterHandle'?: string;
    /**
     * 
     * @type {Token}
     * @memberof Guest
     */
    'solsteadToken': Token;
    /**
     * 
     * @type {Wallet}
     * @memberof Guest
     */
    'solsteadWallet': Wallet;
}
/**
 * 
 * @export
 * @interface GuestRequest
 */
export interface GuestRequest {
    /**
     * 
     * @type {Token}
     * @memberof GuestRequest
     */
    'guestToken': Token;
    /**
     * 
     * @type {Wallet}
     * @memberof GuestRequest
     */
    'guestWallet': Wallet;
    /**
     * 
     * @type {string}
     * @memberof GuestRequest
     */
    'guestTwitterHandle'?: string;
    /**
     * 
     * @type {Token}
     * @memberof GuestRequest
     */
    'solsteadToken': Token;
    /**
     * 
     * @type {Wallet}
     * @memberof GuestRequest
     */
    'solsteadWallet': Wallet;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    'publicKey': string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    'signature'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    'transaction'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    'nonce'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {Array<GuestRequest>}
     * @memberof InlineResponse200
     */
    'guestApprovals'?: Array<GuestRequest>;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {Array<GuestRequest>}
     * @memberof InlineResponse2001
     */
    'guestRequests'?: Array<GuestRequest>;
}
/**
 * 
 * @export
 * @interface Metadata
 */
export interface Metadata {
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'symbol'?: string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof Metadata
     */
    'seller_fee_basis_points'?: number;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'animation_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    'external_url'?: string;
    /**
     * 
     * @type {Array<Attribute>}
     * @memberof Metadata
     */
    'attributes'?: Array<Attribute>;
    /**
     * 
     * @type {Collection}
     * @memberof Metadata
     */
    'collection'?: Collection;
    /**
     * 
     * @type {MetadataProperties}
     * @memberof Metadata
     */
    'properties'?: MetadataProperties;
}
/**
 * 
 * @export
 * @interface MetadataProperties
 */
export interface MetadataProperties {
    /**
     * 
     * @type {string}
     * @memberof MetadataProperties
     */
    'category'?: string;
    /**
     * 
     * @type {Array<MetadataPropertiesFiles>}
     * @memberof MetadataProperties
     */
    'files'?: Array<MetadataPropertiesFiles>;
    /**
     * 
     * @type {Array<Creator>}
     * @memberof MetadataProperties
     */
    'creators'?: Array<Creator>;
}
/**
 * 
 * @export
 * @interface MetadataPropertiesFiles
 */
export interface MetadataPropertiesFiles {
    /**
     * 
     * @type {string}
     * @memberof MetadataPropertiesFiles
     */
    'uri'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataPropertiesFiles
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MetadataPropertiesFiles
     */
    'cdn'?: boolean;
}
/**
 * 
 * @export
 * @interface MySolsteadsResponse
 */
export interface MySolsteadsResponse {
    /**
     * 
     * @type {Array<Token>}
     * @memberof MySolsteadsResponse
     */
    'citizenTokens'?: Array<Token>;
    /**
     * 
     * @type {Array<Token>}
     * @memberof MySolsteadsResponse
     */
    'solsteadTokens'?: Array<Token>;
}
/**
 * 
 * @export
 * @interface OnChainMetadata
 */
export interface OnChainMetadata {
    /**
     * 
     * @type {string}
     * @memberof OnChainMetadata
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnChainMetadata
     */
    'symbol'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnChainMetadata
     */
    'uri'?: string;
    /**
     * 
     * @type {Array<Creator>}
     * @memberof OnChainMetadata
     */
    'creators'?: Array<Creator>;
    /**
     * 
     * @type {string}
     * @memberof OnChainMetadata
     */
    'update_authority'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OnChainMetadata
     */
    'primary_sale_happened'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OnChainMetadata
     */
    'seller_fee_basis_points'?: number;
}
/**
 * 
 * @export
 * @interface Owner
 */
export interface Owner {
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    'twitterHandle'?: string;
}
/**
 * 
 * @export
 * @interface SetWalletTokenGalleryFilterRequestBody
 */
export interface SetWalletTokenGalleryFilterRequestBody {
    /**
     * 
     * @type {Array<TokenIdentifiers>}
     * @memberof SetWalletTokenGalleryFilterRequestBody
     */
    'filter'?: Array<TokenIdentifiers>;
}
/**
 * 
 * @export
 * @interface SetWalletTokenGalleryOrderRequestBody
 */
export interface SetWalletTokenGalleryOrderRequestBody {
    /**
     * 
     * @type {Array<TokenIdentifiers>}
     * @memberof SetWalletTokenGalleryOrderRequestBody
     */
    'order'?: Array<TokenIdentifiers>;
}
/**
 * 
 * @export
 * @interface Solstead
 */
export interface Solstead {
    /**
     * 
     * @type {string}
     * @memberof Solstead
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Solstead
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Solstead
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof Solstead
     */
    'bannerImage'?: string;
    /**
     * 
     * @type {Biome}
     * @memberof Solstead
     */
    'biome'?: Biome;
    /**
     * 
     * @type {Frame}
     * @memberof Solstead
     */
    'defaultFrame'?: Frame;
    /**
     * 
     * @type {Array<Guest>}
     * @memberof Solstead
     */
    'guests'?: Array<Guest>;
    /**
     * 
     * @type {Street}
     * @memberof Solstead
     */
    'street'?: Street;
    /**
     * 
     * @type {Token}
     * @memberof Solstead
     */
    'token'?: Token;
    /**
     * 
     * @type {Owner}
     * @memberof Solstead
     */
    'owner'?: Owner;
    /**
     * 
     * @type {Gallery}
     * @memberof Solstead
     */
    'gallery'?: Gallery;
    /**
     * 
     * @type {Array<CommunityMember>}
     * @memberof Solstead
     */
    'communityMembers'?: Array<CommunityMember>;
}
/**
 * 
 * @export
 * @interface Street
 */
export interface Street {
    /**
     * 
     * @type {string}
     * @memberof Street
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'blockchain': string;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'address': string;
    /**
     * 
     * @type {Metadata}
     * @memberof Token
     */
    'metadata'?: Metadata;
}
/**
 * 
 * @export
 * @interface TokenIdentifiers
 */
export interface TokenIdentifiers {
    /**
     * 
     * @type {string}
     * @memberof TokenIdentifiers
     */
    'blockchain'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenIdentifiers
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenIdentifiers
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'nonce'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'twitterHandle'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'primaryWalletAddress'?: string;
    /**
     * 
     * @type {Array<Wallet>}
     * @memberof User
     */
    'wallets'?: Array<Wallet>;
}
/**
 * 
 * @export
 * @interface Wallet
 */
export interface Wallet {
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    'blockchain'?: string;
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    'address'?: string;
}

/**
 * SolsteadsApi - axios parameter creator
 * @export
 */
export const SolsteadsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Remove a guest approval
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {AddGuestApprovalRequestBody} [addGuestApprovalRequestBody] The wallet to be disconnected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGuestApproval: async (walletAddress: string, tokenAddress: string, addGuestApprovalRequestBody?: AddGuestApprovalRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletAddress' is not null or undefined
            assertParamExists('addGuestApproval', 'walletAddress', walletAddress)
            // verify required parameter 'tokenAddress' is not null or undefined
            assertParamExists('addGuestApproval', 'tokenAddress', tokenAddress)
            const localVarPath = `/v0/users/{walletAddress}/tokens/{tokenAddress}/guest-approvals`
                .replace(`{${"walletAddress"}}`, encodeURIComponent(String(walletAddress)))
                .replace(`{${"tokenAddress"}}`, encodeURIComponent(String(tokenAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addGuestApprovalRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a guest request
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {AddGuestRequestRequestBody} [addGuestRequestRequestBody] The wallet to be disconnected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGuestRequest: async (walletAddress: string, tokenAddress: string, addGuestRequestRequestBody?: AddGuestRequestRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletAddress' is not null or undefined
            assertParamExists('addGuestRequest', 'walletAddress', walletAddress)
            // verify required parameter 'tokenAddress' is not null or undefined
            assertParamExists('addGuestRequest', 'tokenAddress', tokenAddress)
            const localVarPath = `/v0/users/{walletAddress}/tokens/{tokenAddress}/guest-requests`
                .replace(`{${"walletAddress"}}`, encodeURIComponent(String(walletAddress)))
                .replace(`{${"tokenAddress"}}`, encodeURIComponent(String(tokenAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addGuestRequestRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a secondary wallet
         * @param {string} walletAddress 
         * @param {AddWalletParameters} [addWalletParameters] The wallet to be disconnected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWalletV0: async (walletAddress: string, addWalletParameters?: AddWalletParameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletAddress' is not null or undefined
            assertParamExists('addWalletV0', 'walletAddress', walletAddress)
            const localVarPath = `/v0/users/{walletAddress}/wallets`
                .replace(`{${"walletAddress"}}`, encodeURIComponent(String(walletAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addWalletParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request an access token using a signed message or transaction
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessToken: async (inlineObject: InlineObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject' is not null or undefined
            assertParamExists('createAccessToken', 'inlineObject', inlineObject)
            const localVarPath = `/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a guest approval
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {DeleteGuestApprovalRequestBody} [deleteGuestApprovalRequestBody] The wallet to be disconnected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGuestApproval: async (walletAddress: string, tokenAddress: string, deleteGuestApprovalRequestBody?: DeleteGuestApprovalRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletAddress' is not null or undefined
            assertParamExists('deleteGuestApproval', 'walletAddress', walletAddress)
            // verify required parameter 'tokenAddress' is not null or undefined
            assertParamExists('deleteGuestApproval', 'tokenAddress', tokenAddress)
            const localVarPath = `/v0/users/{walletAddress}/tokens/{tokenAddress}/guest-approvals`
                .replace(`{${"walletAddress"}}`, encodeURIComponent(String(walletAddress)))
                .replace(`{${"tokenAddress"}}`, encodeURIComponent(String(tokenAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteGuestApprovalRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a guest request
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGuestRequest: async (walletAddress: string, tokenAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletAddress' is not null or undefined
            assertParamExists('deleteGuestRequest', 'walletAddress', walletAddress)
            // verify required parameter 'tokenAddress' is not null or undefined
            assertParamExists('deleteGuestRequest', 'tokenAddress', tokenAddress)
            const localVarPath = `/v0/users/{walletAddress}/tokens/{tokenAddress}/guest-requests`
                .replace(`{${"walletAddress"}}`, encodeURIComponent(String(walletAddress)))
                .replace(`{${"tokenAddress"}}`, encodeURIComponent(String(tokenAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disconnect a secondary wallet
         * @param {string} walletAddress 
         * @param {Wallet} [wallet] The wallet to be disconnected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectWalletV0: async (walletAddress: string, wallet?: Wallet, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletAddress' is not null or undefined
            assertParamExists('disconnectWalletV0', 'walletAddress', walletAddress)
            const localVarPath = `/v0/users/{walletAddress}/wallets`
                .replace(`{${"walletAddress"}}`, encodeURIComponent(String(walletAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wallet, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a guest approval
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuestApprovals: async (walletAddress: string, tokenAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletAddress' is not null or undefined
            assertParamExists('getGuestApprovals', 'walletAddress', walletAddress)
            // verify required parameter 'tokenAddress' is not null or undefined
            assertParamExists('getGuestApprovals', 'tokenAddress', tokenAddress)
            const localVarPath = `/v0/users/{walletAddress}/tokens/{tokenAddress}/guest-approvals`
                .replace(`{${"walletAddress"}}`, encodeURIComponent(String(walletAddress)))
                .replace(`{${"tokenAddress"}}`, encodeURIComponent(String(tokenAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a guest request
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuestRequests: async (walletAddress: string, tokenAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletAddress' is not null or undefined
            assertParamExists('getGuestRequests', 'walletAddress', walletAddress)
            // verify required parameter 'tokenAddress' is not null or undefined
            assertParamExists('getGuestRequests', 'tokenAddress', tokenAddress)
            const localVarPath = `/v0/users/{walletAddress}/tokens/{tokenAddress}/guest-requests`
                .replace(`{${"walletAddress"}}`, encodeURIComponent(String(walletAddress)))
                .replace(`{${"tokenAddress"}}`, encodeURIComponent(String(tokenAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the data needed for the my-solsteads page
         * @param {string} walletAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMySolsteads: async (walletAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletAddress' is not null or undefined
            assertParamExists('getMySolsteads', 'walletAddress', walletAddress)
            const localVarPath = `/v0/summary/my-solsteads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (walletAddress !== undefined) {
                localVarQueryParameter['walletAddress'] = walletAddress;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the Solstead\'s gallery data
         * @param {string} solsteadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolstead: async (solsteadId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'solsteadId' is not null or undefined
            assertParamExists('getSolstead', 'solsteadId', solsteadId)
            const localVarPath = `/solsteads/{solsteadId}`
                .replace(`{${"solsteadId"}}`, encodeURIComponent(String(solsteadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the Solstead\'s Holidays 2021 Entry
         * @param {string} solsteadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolsteadHolidays2021Entry: async (solsteadId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'solsteadId' is not null or undefined
            assertParamExists('getSolsteadHolidays2021Entry', 'solsteadId', solsteadId)
            const localVarPath = `/solsteads/{solsteadId}/holidays-2021`
                .replace(`{${"solsteadId"}}`, encodeURIComponent(String(solsteadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get token metadata
         * @param {string} tokenAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenMetadata: async (tokenAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenAddress' is not null or undefined
            assertParamExists('getTokenMetadata', 'tokenAddress', tokenAddress)
            const localVarPath = `/tokens/{tokenAddress}/metadata`
                .replace(`{${"tokenAddress"}}`, encodeURIComponent(String(tokenAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get token holder wallet
         * @param {string} tokenAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenWallet: async (tokenAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenAddress' is not null or undefined
            assertParamExists('getTokenWallet', 'tokenAddress', tokenAddress)
            const localVarPath = `/tokens/{tokenAddress}/wallet`
                .replace(`{${"tokenAddress"}}`, encodeURIComponent(String(tokenAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a user by their walletAddress
         * @param {string} walletAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserV0: async (walletAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletAddress' is not null or undefined
            assertParamExists('getUserV0', 'walletAddress', walletAddress)
            const localVarPath = `/v0/users/{walletAddress}`
                .replace(`{${"walletAddress"}}`, encodeURIComponent(String(walletAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get NFT collections that exist in the wallet
         * @param {string} walletAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletCollections: async (walletAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletAddress' is not null or undefined
            assertParamExists('getWalletCollections', 'walletAddress', walletAddress)
            const localVarPath = `/wallets/{walletAddress}/collections`
                .replace(`{${"walletAddress"}}`, encodeURIComponent(String(walletAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get NFTs the data to display the gallery
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletTokenGallerySettings: async (walletAddress: string, tokenAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletAddress' is not null or undefined
            assertParamExists('getWalletTokenGallerySettings', 'walletAddress', walletAddress)
            // verify required parameter 'tokenAddress' is not null or undefined
            assertParamExists('getWalletTokenGallerySettings', 'tokenAddress', tokenAddress)
            const localVarPath = `/wallets/{walletAddress}/tokens/{tokenAddress}/gallery/settings`
                .replace(`{${"walletAddress"}}`, encodeURIComponent(String(walletAddress)))
                .replace(`{${"tokenAddress"}}`, encodeURIComponent(String(tokenAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get NFTs that exist in the wallet
         * @param {string} walletAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletTokens: async (walletAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletAddress' is not null or undefined
            assertParamExists('getWalletTokens', 'walletAddress', walletAddress)
            const localVarPath = `/wallets/{walletAddress}/tokens`
                .replace(`{${"walletAddress"}}`, encodeURIComponent(String(walletAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Twitter handle associated with wallet
         * @param {string} walletAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletTwitterHandle: async (walletAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletAddress' is not null or undefined
            assertParamExists('getWalletTwitterHandle', 'walletAddress', walletAddress)
            const localVarPath = `/wallets/{walletAddress}/twitter-handle`
                .replace(`{${"walletAddress"}}`, encodeURIComponent(String(walletAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the order of the NFTs in the gallery
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {SetWalletTokenGalleryFilterRequestBody} setWalletTokenGalleryFilterRequestBody An array containing the token addresses in the order in which they should be displayed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWalletTokenGalleryFilter: async (walletAddress: string, tokenAddress: string, setWalletTokenGalleryFilterRequestBody: SetWalletTokenGalleryFilterRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletAddress' is not null or undefined
            assertParamExists('setWalletTokenGalleryFilter', 'walletAddress', walletAddress)
            // verify required parameter 'tokenAddress' is not null or undefined
            assertParamExists('setWalletTokenGalleryFilter', 'tokenAddress', tokenAddress)
            // verify required parameter 'setWalletTokenGalleryFilterRequestBody' is not null or undefined
            assertParamExists('setWalletTokenGalleryFilter', 'setWalletTokenGalleryFilterRequestBody', setWalletTokenGalleryFilterRequestBody)
            const localVarPath = `/wallets/{walletAddress}/tokens/{tokenAddress}/gallery/settings/filter`
                .replace(`{${"walletAddress"}}`, encodeURIComponent(String(walletAddress)))
                .replace(`{${"tokenAddress"}}`, encodeURIComponent(String(tokenAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setWalletTokenGalleryFilterRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the order of the NFTs in the gallery
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {SetWalletTokenGalleryOrderRequestBody} setWalletTokenGalleryOrderRequestBody An array containing the token addresses in the order in which they should be displayed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWalletTokenGalleryOrder: async (walletAddress: string, tokenAddress: string, setWalletTokenGalleryOrderRequestBody: SetWalletTokenGalleryOrderRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletAddress' is not null or undefined
            assertParamExists('setWalletTokenGalleryOrder', 'walletAddress', walletAddress)
            // verify required parameter 'tokenAddress' is not null or undefined
            assertParamExists('setWalletTokenGalleryOrder', 'tokenAddress', tokenAddress)
            // verify required parameter 'setWalletTokenGalleryOrderRequestBody' is not null or undefined
            assertParamExists('setWalletTokenGalleryOrder', 'setWalletTokenGalleryOrderRequestBody', setWalletTokenGalleryOrderRequestBody)
            const localVarPath = `/wallets/{walletAddress}/tokens/{tokenAddress}/gallery/settings/order`
                .replace(`{${"walletAddress"}}`, encodeURIComponent(String(walletAddress)))
                .replace(`{${"tokenAddress"}}`, encodeURIComponent(String(tokenAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setWalletTokenGalleryOrderRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SolsteadsApi - functional programming interface
 * @export
 */
export const SolsteadsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SolsteadsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Remove a guest approval
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {AddGuestApprovalRequestBody} [addGuestApprovalRequestBody] The wallet to be disconnected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addGuestApproval(walletAddress: string, tokenAddress: string, addGuestApprovalRequestBody?: AddGuestApprovalRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addGuestApproval(walletAddress, tokenAddress, addGuestApprovalRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a guest request
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {AddGuestRequestRequestBody} [addGuestRequestRequestBody] The wallet to be disconnected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addGuestRequest(walletAddress: string, tokenAddress: string, addGuestRequestRequestBody?: AddGuestRequestRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addGuestRequest(walletAddress, tokenAddress, addGuestRequestRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a secondary wallet
         * @param {string} walletAddress 
         * @param {AddWalletParameters} [addWalletParameters] The wallet to be disconnected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addWalletV0(walletAddress: string, addWalletParameters?: AddWalletParameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addWalletV0(walletAddress, addWalletParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request an access token using a signed message or transaction
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccessToken(inlineObject: InlineObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccessToken(inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a guest approval
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {DeleteGuestApprovalRequestBody} [deleteGuestApprovalRequestBody] The wallet to be disconnected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGuestApproval(walletAddress: string, tokenAddress: string, deleteGuestApprovalRequestBody?: DeleteGuestApprovalRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGuestApproval(walletAddress, tokenAddress, deleteGuestApprovalRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a guest request
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGuestRequest(walletAddress: string, tokenAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGuestRequest(walletAddress, tokenAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Disconnect a secondary wallet
         * @param {string} walletAddress 
         * @param {Wallet} [wallet] The wallet to be disconnected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disconnectWalletV0(walletAddress: string, wallet?: Wallet, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disconnectWalletV0(walletAddress, wallet, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a guest approval
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuestApprovals(walletAddress: string, tokenAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuestApprovals(walletAddress, tokenAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a guest request
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuestRequests(walletAddress: string, tokenAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuestRequests(walletAddress, tokenAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the data needed for the my-solsteads page
         * @param {string} walletAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMySolsteads(walletAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MySolsteadsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMySolsteads(walletAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the Solstead\'s gallery data
         * @param {string} solsteadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSolstead(solsteadId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSolsteadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSolstead(solsteadId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the Solstead\'s Holidays 2021 Entry
         * @param {string} solsteadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSolsteadHolidays2021Entry(solsteadId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSolsteadHoliday2021EntryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSolsteadHolidays2021Entry(solsteadId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get token metadata
         * @param {string} tokenAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTokenMetadata(tokenAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTokenMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTokenMetadata(tokenAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get token holder wallet
         * @param {string} tokenAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTokenWallet(tokenAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTokenWalletResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTokenWallet(tokenAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a user by their walletAddress
         * @param {string} walletAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserV0(walletAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserV0(walletAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get NFT collections that exist in the wallet
         * @param {string} walletAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletCollections(walletAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWalletCollectionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWalletCollections(walletAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get NFTs the data to display the gallery
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletTokenGallerySettings(walletAddress: string, tokenAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWalletTokenGallerySettings(walletAddress, tokenAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get NFTs that exist in the wallet
         * @param {string} walletAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletTokens(walletAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWalletTokensResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWalletTokens(walletAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Twitter handle associated with wallet
         * @param {string} walletAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletTwitterHandle(walletAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWalletTwitterHandleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWalletTwitterHandle(walletAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set the order of the NFTs in the gallery
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {SetWalletTokenGalleryFilterRequestBody} setWalletTokenGalleryFilterRequestBody An array containing the token addresses in the order in which they should be displayed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setWalletTokenGalleryFilter(walletAddress: string, tokenAddress: string, setWalletTokenGalleryFilterRequestBody: SetWalletTokenGalleryFilterRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setWalletTokenGalleryFilter(walletAddress, tokenAddress, setWalletTokenGalleryFilterRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set the order of the NFTs in the gallery
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {SetWalletTokenGalleryOrderRequestBody} setWalletTokenGalleryOrderRequestBody An array containing the token addresses in the order in which they should be displayed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setWalletTokenGalleryOrder(walletAddress: string, tokenAddress: string, setWalletTokenGalleryOrderRequestBody: SetWalletTokenGalleryOrderRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setWalletTokenGalleryOrder(walletAddress, tokenAddress, setWalletTokenGalleryOrderRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SolsteadsApi - factory interface
 * @export
 */
export const SolsteadsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SolsteadsApiFp(configuration)
    return {
        /**
         * 
         * @summary Remove a guest approval
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {AddGuestApprovalRequestBody} [addGuestApprovalRequestBody] The wallet to be disconnected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGuestApproval(walletAddress: string, tokenAddress: string, addGuestApprovalRequestBody?: AddGuestApprovalRequestBody, options?: any): AxiosPromise<void> {
            return localVarFp.addGuestApproval(walletAddress, tokenAddress, addGuestApprovalRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a guest request
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {AddGuestRequestRequestBody} [addGuestRequestRequestBody] The wallet to be disconnected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGuestRequest(walletAddress: string, tokenAddress: string, addGuestRequestRequestBody?: AddGuestRequestRequestBody, options?: any): AxiosPromise<void> {
            return localVarFp.addGuestRequest(walletAddress, tokenAddress, addGuestRequestRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a secondary wallet
         * @param {string} walletAddress 
         * @param {AddWalletParameters} [addWalletParameters] The wallet to be disconnected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWalletV0(walletAddress: string, addWalletParameters?: AddWalletParameters, options?: any): AxiosPromise<void> {
            return localVarFp.addWalletV0(walletAddress, addWalletParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request an access token using a signed message or transaction
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessToken(inlineObject: InlineObject, options?: any): AxiosPromise<void> {
            return localVarFp.createAccessToken(inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a guest approval
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {DeleteGuestApprovalRequestBody} [deleteGuestApprovalRequestBody] The wallet to be disconnected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGuestApproval(walletAddress: string, tokenAddress: string, deleteGuestApprovalRequestBody?: DeleteGuestApprovalRequestBody, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGuestApproval(walletAddress, tokenAddress, deleteGuestApprovalRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a guest request
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGuestRequest(walletAddress: string, tokenAddress: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGuestRequest(walletAddress, tokenAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disconnect a secondary wallet
         * @param {string} walletAddress 
         * @param {Wallet} [wallet] The wallet to be disconnected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectWalletV0(walletAddress: string, wallet?: Wallet, options?: any): AxiosPromise<void> {
            return localVarFp.disconnectWalletV0(walletAddress, wallet, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a guest approval
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuestApprovals(walletAddress: string, tokenAddress: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.getGuestApprovals(walletAddress, tokenAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a guest request
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuestRequests(walletAddress: string, tokenAddress: string, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.getGuestRequests(walletAddress, tokenAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the data needed for the my-solsteads page
         * @param {string} walletAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMySolsteads(walletAddress: string, options?: any): AxiosPromise<MySolsteadsResponse> {
            return localVarFp.getMySolsteads(walletAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the Solstead\'s gallery data
         * @param {string} solsteadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolstead(solsteadId: string, options?: any): AxiosPromise<GetSolsteadResponse> {
            return localVarFp.getSolstead(solsteadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the Solstead\'s Holidays 2021 Entry
         * @param {string} solsteadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolsteadHolidays2021Entry(solsteadId: string, options?: any): AxiosPromise<GetSolsteadHoliday2021EntryResponse> {
            return localVarFp.getSolsteadHolidays2021Entry(solsteadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get token metadata
         * @param {string} tokenAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenMetadata(tokenAddress: string, options?: any): AxiosPromise<GetTokenMetadataResponse> {
            return localVarFp.getTokenMetadata(tokenAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get token holder wallet
         * @param {string} tokenAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenWallet(tokenAddress: string, options?: any): AxiosPromise<GetTokenWalletResponse> {
            return localVarFp.getTokenWallet(tokenAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a user by their walletAddress
         * @param {string} walletAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserV0(walletAddress: string, options?: any): AxiosPromise<GetUserResponse> {
            return localVarFp.getUserV0(walletAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get NFT collections that exist in the wallet
         * @param {string} walletAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletCollections(walletAddress: string, options?: any): AxiosPromise<GetWalletCollectionsResponse> {
            return localVarFp.getWalletCollections(walletAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get NFTs the data to display the gallery
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletTokenGallerySettings(walletAddress: string, tokenAddress: string, options?: any): AxiosPromise<object> {
            return localVarFp.getWalletTokenGallerySettings(walletAddress, tokenAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get NFTs that exist in the wallet
         * @param {string} walletAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletTokens(walletAddress: string, options?: any): AxiosPromise<GetWalletTokensResponse> {
            return localVarFp.getWalletTokens(walletAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Twitter handle associated with wallet
         * @param {string} walletAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletTwitterHandle(walletAddress: string, options?: any): AxiosPromise<GetWalletTwitterHandleResponse> {
            return localVarFp.getWalletTwitterHandle(walletAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set the order of the NFTs in the gallery
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {SetWalletTokenGalleryFilterRequestBody} setWalletTokenGalleryFilterRequestBody An array containing the token addresses in the order in which they should be displayed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWalletTokenGalleryFilter(walletAddress: string, tokenAddress: string, setWalletTokenGalleryFilterRequestBody: SetWalletTokenGalleryFilterRequestBody, options?: any): AxiosPromise<void> {
            return localVarFp.setWalletTokenGalleryFilter(walletAddress, tokenAddress, setWalletTokenGalleryFilterRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set the order of the NFTs in the gallery
         * @param {string} walletAddress 
         * @param {string} tokenAddress 
         * @param {SetWalletTokenGalleryOrderRequestBody} setWalletTokenGalleryOrderRequestBody An array containing the token addresses in the order in which they should be displayed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWalletTokenGalleryOrder(walletAddress: string, tokenAddress: string, setWalletTokenGalleryOrderRequestBody: SetWalletTokenGalleryOrderRequestBody, options?: any): AxiosPromise<void> {
            return localVarFp.setWalletTokenGalleryOrder(walletAddress, tokenAddress, setWalletTokenGalleryOrderRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SolsteadsApi - object-oriented interface
 * @export
 * @class SolsteadsApi
 * @extends {BaseAPI}
 */
export class SolsteadsApi extends BaseAPI {
    /**
     * 
     * @summary Remove a guest approval
     * @param {string} walletAddress 
     * @param {string} tokenAddress 
     * @param {AddGuestApprovalRequestBody} [addGuestApprovalRequestBody] The wallet to be disconnected
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolsteadsApi
     */
    public addGuestApproval(walletAddress: string, tokenAddress: string, addGuestApprovalRequestBody?: AddGuestApprovalRequestBody, options?: AxiosRequestConfig) {
        return SolsteadsApiFp(this.configuration).addGuestApproval(walletAddress, tokenAddress, addGuestApprovalRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a guest request
     * @param {string} walletAddress 
     * @param {string} tokenAddress 
     * @param {AddGuestRequestRequestBody} [addGuestRequestRequestBody] The wallet to be disconnected
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolsteadsApi
     */
    public addGuestRequest(walletAddress: string, tokenAddress: string, addGuestRequestRequestBody?: AddGuestRequestRequestBody, options?: AxiosRequestConfig) {
        return SolsteadsApiFp(this.configuration).addGuestRequest(walletAddress, tokenAddress, addGuestRequestRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a secondary wallet
     * @param {string} walletAddress 
     * @param {AddWalletParameters} [addWalletParameters] The wallet to be disconnected
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolsteadsApi
     */
    public addWalletV0(walletAddress: string, addWalletParameters?: AddWalletParameters, options?: AxiosRequestConfig) {
        return SolsteadsApiFp(this.configuration).addWalletV0(walletAddress, addWalletParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request an access token using a signed message or transaction
     * @param {InlineObject} inlineObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolsteadsApi
     */
    public createAccessToken(inlineObject: InlineObject, options?: AxiosRequestConfig) {
        return SolsteadsApiFp(this.configuration).createAccessToken(inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a guest approval
     * @param {string} walletAddress 
     * @param {string} tokenAddress 
     * @param {DeleteGuestApprovalRequestBody} [deleteGuestApprovalRequestBody] The wallet to be disconnected
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolsteadsApi
     */
    public deleteGuestApproval(walletAddress: string, tokenAddress: string, deleteGuestApprovalRequestBody?: DeleteGuestApprovalRequestBody, options?: AxiosRequestConfig) {
        return SolsteadsApiFp(this.configuration).deleteGuestApproval(walletAddress, tokenAddress, deleteGuestApprovalRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a guest request
     * @param {string} walletAddress 
     * @param {string} tokenAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolsteadsApi
     */
    public deleteGuestRequest(walletAddress: string, tokenAddress: string, options?: AxiosRequestConfig) {
        return SolsteadsApiFp(this.configuration).deleteGuestRequest(walletAddress, tokenAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disconnect a secondary wallet
     * @param {string} walletAddress 
     * @param {Wallet} [wallet] The wallet to be disconnected
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolsteadsApi
     */
    public disconnectWalletV0(walletAddress: string, wallet?: Wallet, options?: AxiosRequestConfig) {
        return SolsteadsApiFp(this.configuration).disconnectWalletV0(walletAddress, wallet, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a guest approval
     * @param {string} walletAddress 
     * @param {string} tokenAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolsteadsApi
     */
    public getGuestApprovals(walletAddress: string, tokenAddress: string, options?: AxiosRequestConfig) {
        return SolsteadsApiFp(this.configuration).getGuestApprovals(walletAddress, tokenAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a guest request
     * @param {string} walletAddress 
     * @param {string} tokenAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolsteadsApi
     */
    public getGuestRequests(walletAddress: string, tokenAddress: string, options?: AxiosRequestConfig) {
        return SolsteadsApiFp(this.configuration).getGuestRequests(walletAddress, tokenAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the data needed for the my-solsteads page
     * @param {string} walletAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolsteadsApi
     */
    public getMySolsteads(walletAddress: string, options?: AxiosRequestConfig) {
        return SolsteadsApiFp(this.configuration).getMySolsteads(walletAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the Solstead\'s gallery data
     * @param {string} solsteadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolsteadsApi
     */
    public getSolstead(solsteadId: string, options?: AxiosRequestConfig) {
        return SolsteadsApiFp(this.configuration).getSolstead(solsteadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the Solstead\'s Holidays 2021 Entry
     * @param {string} solsteadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolsteadsApi
     */
    public getSolsteadHolidays2021Entry(solsteadId: string, options?: AxiosRequestConfig) {
        return SolsteadsApiFp(this.configuration).getSolsteadHolidays2021Entry(solsteadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get token metadata
     * @param {string} tokenAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolsteadsApi
     */
    public getTokenMetadata(tokenAddress: string, options?: AxiosRequestConfig) {
        return SolsteadsApiFp(this.configuration).getTokenMetadata(tokenAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get token holder wallet
     * @param {string} tokenAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolsteadsApi
     */
    public getTokenWallet(tokenAddress: string, options?: AxiosRequestConfig) {
        return SolsteadsApiFp(this.configuration).getTokenWallet(tokenAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a user by their walletAddress
     * @param {string} walletAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolsteadsApi
     */
    public getUserV0(walletAddress: string, options?: AxiosRequestConfig) {
        return SolsteadsApiFp(this.configuration).getUserV0(walletAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get NFT collections that exist in the wallet
     * @param {string} walletAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolsteadsApi
     */
    public getWalletCollections(walletAddress: string, options?: AxiosRequestConfig) {
        return SolsteadsApiFp(this.configuration).getWalletCollections(walletAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get NFTs the data to display the gallery
     * @param {string} walletAddress 
     * @param {string} tokenAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolsteadsApi
     */
    public getWalletTokenGallerySettings(walletAddress: string, tokenAddress: string, options?: AxiosRequestConfig) {
        return SolsteadsApiFp(this.configuration).getWalletTokenGallerySettings(walletAddress, tokenAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get NFTs that exist in the wallet
     * @param {string} walletAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolsteadsApi
     */
    public getWalletTokens(walletAddress: string, options?: AxiosRequestConfig) {
        return SolsteadsApiFp(this.configuration).getWalletTokens(walletAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Twitter handle associated with wallet
     * @param {string} walletAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolsteadsApi
     */
    public getWalletTwitterHandle(walletAddress: string, options?: AxiosRequestConfig) {
        return SolsteadsApiFp(this.configuration).getWalletTwitterHandle(walletAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set the order of the NFTs in the gallery
     * @param {string} walletAddress 
     * @param {string} tokenAddress 
     * @param {SetWalletTokenGalleryFilterRequestBody} setWalletTokenGalleryFilterRequestBody An array containing the token addresses in the order in which they should be displayed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolsteadsApi
     */
    public setWalletTokenGalleryFilter(walletAddress: string, tokenAddress: string, setWalletTokenGalleryFilterRequestBody: SetWalletTokenGalleryFilterRequestBody, options?: AxiosRequestConfig) {
        return SolsteadsApiFp(this.configuration).setWalletTokenGalleryFilter(walletAddress, tokenAddress, setWalletTokenGalleryFilterRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set the order of the NFTs in the gallery
     * @param {string} walletAddress 
     * @param {string} tokenAddress 
     * @param {SetWalletTokenGalleryOrderRequestBody} setWalletTokenGalleryOrderRequestBody An array containing the token addresses in the order in which they should be displayed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolsteadsApi
     */
    public setWalletTokenGalleryOrder(walletAddress: string, tokenAddress: string, setWalletTokenGalleryOrderRequestBody: SetWalletTokenGalleryOrderRequestBody, options?: AxiosRequestConfig) {
        return SolsteadsApiFp(this.configuration).setWalletTokenGalleryOrder(walletAddress, tokenAddress, setWalletTokenGalleryOrderRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


