import { ThemeProvider } from "styled-components";
import { QueryClient, QueryClientProvider } from "react-query";
import { ProvideAuth } from "../hooks";
import { Analytics } from "@vercel/analytics/react";

import "../styles/globals.css";
import "tachyons/css/tachyons.css";

const theme = {
  color: {
    blue: "#0f1954",
    darkgreen: "#175b56",
    lavender: "#c3b3e2",
    palegreen: "#cfe27f",
    white: "#efe4e1",
  },
};

const queryClient = new QueryClient();
queryClient.setDefaultOptions({
  queries: {
    retry: false,
    staleTime: Infinity,
  },
});

function SolsteadsApp({ Component, pageProps }) {
  return (
    <ThemeProvider theme={theme}>
      <ProvideAuth>
        <QueryClientProvider client={queryClient}>
          <Component {...pageProps} />
          <Analytics />
        </QueryClientProvider>
      </ProvideAuth>
    </ThemeProvider>
  );
}

export default SolsteadsApp;
