import { AxiosInstance, AxiosResponse } from "axios";
import {
  deleteWalletTokenGalleryAudiusTrack,
  deleteWalletTokenGalleryFrame,
  setWalletTokenGalleryAudiusTrack,
  setWalletTokenGalleryFrame,
} from "./api";
import { getWalletTokenGallerySettings } from "./api/walletTokenGallerySettings";
import { getWalletTokens } from "./api/walletTokens";
import { getClient } from "./client";

type SolsteadsSDKConfig = {
  authToken?: string;
  baseURL?: string;
};

export class SolsteadsSDK {
  client: AxiosInstance;
  config: SolsteadsSDKConfig;

  constructor(config?: SolsteadsSDKConfig) {
    this.updateConfig(config);
  }

  updateConfig(config: SolsteadsSDKConfig) {
    this.client = getClient(config.authToken, config.baseURL);
    this.config = config;
  }

  async createAccessToken(
    params: CreateAccessTokenParams
  ): Promise<CreateAccessTokenResponse> {
    const response = await this.client.post<
      CreateAccessTokenParams,
      AxiosResponse<CreateAccessTokenResponse>
    >("/api/auth/access-token", params);
    return response.data;
  }

  async requestTwitterAccessToken(
    requestBody: TwitterOAuthAccessTokenParams
  ): Promise<TwitterOAuthAccessTokenResponse> {
    const response = await this.client.post<
      TwitterOAuthAccessTokenParams,
      AxiosResponse<TwitterOAuthAccessTokenResponse>
    >("/api/twitter/oauth/access_token", requestBody);
    return response.data;
  }

  async requestTwitterRequestToken(): Promise<RequestTwitterRequestTokenResponse> {
    const response = await this.client.post<
      null,
      AxiosResponse<RequestTwitterRequestTokenResponse>
    >("/api/twitter/oauth/request_token");
    return response.data;
  }

  async verifyTwitterAccountCredentials(
    params: VerifyTwitterAccountCredentialsParams
  ): Promise<VerifyTwitterAccountCredentialsResponse> {
    const response = await this.client.post<
      VerifyTwitterAccountCredentialsParams,
      AxiosResponse<VerifyTwitterAccountCredentialsResponse>
    >("/api/twitter/account/verify_credentials", params);
    return response.data;
  }

  async deleteWalletTwitterHandle(
    params: DeleteWalletTwitterHandleParams
  ): Promise<DeleteWalletTwitterHandleResponse> {
    const response = await this.client.delete<
      DeleteWalletTwitterHandleParams,
      AxiosResponse<DeleteWalletTwitterHandleResponse>
    >(`/api/wallets/${params.walletPublicKey}/twitter-handle`);
    return response.data;
  }

  async getWalletTwitterHandle(
    params: GetWalletTwitterHandleParams
  ): Promise<GetWalletTwitterHandleResponse> {
    const response = await this.client.get<
      GetWalletTwitterHandleParams,
      AxiosResponse<GetWalletTwitterHandleResponse>
    >(`/api/wallets/${params.walletPublicKey}/twitter-handle`);
    return response.data;
  }

  async setWalletTwitterHandle(
    params: SetWalletTwitterHandleParams
  ): Promise<SetWalletTwitterHandleResponse> {
    const response = await this.client.put<
      SetWalletTwitterHandleParams,
      AxiosResponse<SetWalletTwitterHandleResponse>
    >(`/api/wallets/${params.walletPublicKey}/twitter-handle`, params);
    return response.data;
  }

  async getWalletNonce(
    params: GetWalletNonceParams
  ): Promise<GetWalletNonceResponse> {
    const response = await this.client.get<
      GetWalletNonceParams,
      AxiosResponse<GetWalletNonceResponse>
    >(`/api/wallets/${params.publicKey}/nonce`);
    return response.data;
  }

  async getTwitterHandle(): Promise<getTwitterHandleResponse> {
    const response = await this.client.get<
      never,
      AxiosResponse<getTwitterHandleResponse>
    >(`/api/twitter/handle`);
    return response.data;
  }

  public deleteWalletTokenGalleryAudiusTrack =
    deleteWalletTokenGalleryAudiusTrack;
  public deleteWalletTokenGalleryFrame = deleteWalletTokenGalleryFrame;
  public getWalletTokenGallerySettings = getWalletTokenGallerySettings;
  public getWalletTokens = getWalletTokens;
  public setWalletTokenGalleryAudiusTrack = setWalletTokenGalleryAudiusTrack;
  public setWalletTokenGalleryFrame = setWalletTokenGalleryFrame;
}

export type DeleteWalletAudiusTrackParams = {
  walletPublicKey: string;
};

export type DeleteWalletAudiusTrackResponse = "";

export type SetWalletAudiusTrackParams = {
  walletPublicKey: string;
  audiusTrackURL: string;
};

export type SetWalletAudiusTrackResponse = "";

export type getTwitterHandleResponse = {
  twitterHandle: string;
};

export type GetWalletNonceParams = {
  publicKey: string;
};

export type GetWalletNonceResponse = {
  nonce: string;
};

export type CreateAccessTokenParams = {
  publicKey: string;
  signature: string;
};

export type CreateAccessTokenResponse = {
  accessToken: string;
};

export type TwitterOAuthAccessTokenParams = {
  oauthRequestToken: string;
  oauthVerifier: string;
};

export type TwitterOAuthAccessTokenResponse = {
  oauthAccessToken: string;
};

export type RequestTwitterRequestTokenResponse = {
  oauthRequestToken;
};

export type VerifyTwitterAccountCredentialsParams = {
  oauthAccessToken: string;
};

export type VerifyTwitterAccountCredentialsResponse = unknown;

/* Delete Twitter Handle */

export type DeleteWalletTwitterHandleParams = {
  walletPublicKey: string;
};

export type DeleteWalletTwitterHandleResponse = "";

/* Get Twitter Handle */

export type GetWalletTwitterHandleParams = {
  walletPublicKey: string;
};

export type GetWalletTwitterHandleResponse = {
  walletPublicKey: string;
  twitterHandle: string;
};

/* Set Twitter Handle */

export type SetWalletTwitterHandleParams = {
  walletPublicKey: string;
  twitterHandle: string;
};

export type SetWalletTwitterHandleResponse = "";
