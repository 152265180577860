import { useQuery, UseQueryOptions } from "react-query";
import { useAuth } from ".";
import { getSolsteadsApiClient } from "../utils";

export function useGetUserV0(
  params: { primaryWalletAddress: string },
  options?: UseQueryOptions
) {
  const { authToken } = useAuth();
  const solsteadsApi = getSolsteadsApiClient(authToken);

  return useQuery(
    ["users", params.primaryWalletAddress],
    async () => {
      try {
        const response = await solsteadsApi.getUserV0(
          params.primaryWalletAddress
        );
        return response.data;
      } catch (err) {
        console.log(err);
        return undefined;
      }
    },
    {
      ...options,
      staleTime: Infinity,
      retry: true,
    }
  );
}
