import { AxiosResponse } from "axios";
import { SolsteadsSDK } from "..";

export type DeleteWalletTokenGalleryAudiusTrackParams = {
  walletAddress: string;
  tokenAddress: string;
};

export async function deleteWalletTokenGalleryAudiusTrack(
  this: SolsteadsSDK,
  params: DeleteWalletTokenGalleryAudiusTrackParams
): Promise<void> {
  const response = await this.client.delete<
    DeleteWalletTokenGalleryAudiusTrackParams,
    AxiosResponse<void>
  >(
    `/api/wallets/${params.walletAddress}/tokens/${params.tokenAddress}/gallery/settings/audius-track`
  );
  return response.data;
}

export type SetWalletTokenGalleryAudiusTrackParams = {
  walletAddress: string;
  tokenAddress: string;
  trackURL: string;
};

export async function setWalletTokenGalleryAudiusTrack(
  this: SolsteadsSDK,
  params: SetWalletTokenGalleryAudiusTrackParams
): Promise<void> {
  const response = await this.client.put<
    SetWalletTokenGalleryAudiusTrackParams,
    AxiosResponse<void>
  >(
    `/api/wallets/${params.walletAddress}/tokens/${params.tokenAddress}/gallery/settings/audius-track`
  );
  return response.data;
}
