import { useQuery } from "react-query";
import { useAuth } from ".";
import { SolsteadsSDK } from "../solsteads-sdk";

export function useGetTwitterHandle() {
  const { authToken } = useAuth();
  const solsteadsSDK = new SolsteadsSDK({ authToken });

  return useQuery(["twitter-handle"], () => {
    return solsteadsSDK.getTwitterHandle();
  });
}
