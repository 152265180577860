import { AxiosResponse } from "axios";
import { SolsteadsSDK } from "..";

export type GetWalletTokenGallerySettingsParams = {
  walletAddress: string;
  tokenAddress: string;
};

export type GetWalletTokenGallerySettingsResponse = {
  audiusTrackId?: string;
  frameId?: string;
};

export async function getWalletTokenGallerySettings(
  this: SolsteadsSDK,
  params: GetWalletTokenGallerySettingsParams
): Promise<GetWalletTokenGallerySettingsResponse> {
  const response = await this.client.get<
    GetWalletTokenGallerySettingsParams,
    AxiosResponse<GetWalletTokenGallerySettingsResponse>
  >(
    `/api/wallets/${params.walletAddress}/tokens/${params.tokenAddress}/gallery/settings`
  );
  return response.data;
}
