import { AxiosResponse } from "axios";
import { SolsteadsSDK } from "..";

export type GetWalletTokensParams = {
  walletAddress: string;
  collectionFamily?: string;
  collectionName?: string;
};

export type GetWalletTokensResponse = {
  tokens: { address: string }[];
};

export async function getWalletTokens(
  this: SolsteadsSDK,
  params: GetWalletTokensParams
): Promise<GetWalletTokensResponse> {
  const response = await this.client.get<
    GetWalletTokensParams,
    AxiosResponse<GetWalletTokensResponse>
  >(
    `/api/wallets/${params.walletAddress}/tokens?${
      params.collectionFamily
        ? `collection.family=${params.collectionFamily}`
        : ""
    }${
      params.collectionName ? `&collection.name=${params.collectionName}` : ""
    }`
  );
  return response.data;
}
