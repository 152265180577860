import { useEffect } from "react";
import jwt from "jsonwebtoken";
import { useContext, createContext } from "react";
import useLocalStorage from "./useLocalStorage";

const WALLET_PUBLIC_KEY_LOCAL_STORAGE_KEY = "solsteadsWalletPublicKey";
const AUTH_TOKEN_LOCAL_STORAGE_KEY = "solsteadsAuthToken";
const TWITTER_ACCESS_TOKEN_STORAGE_KEY = "solsteadsTwitterAccessToken";

const authContext = createContext(undefined);

function ProvideAuth({ children }) {
  const [walletPublicKey, setWalletPublicKey] = useLocalStorage(
    WALLET_PUBLIC_KEY_LOCAL_STORAGE_KEY,
    null
  );
  const [authToken, setAuthToken] = useLocalStorage(
    AUTH_TOKEN_LOCAL_STORAGE_KEY,
    null
  );

  const [twitterAccessToken, setTwitterAccessToken] = useLocalStorage(
    TWITTER_ACCESS_TOKEN_STORAGE_KEY,
    null
  );

  function logIn(walletPublicKey, authToken) {
    setWalletPublicKey(walletPublicKey);
    setAuthToken(authToken);
  }

  const logOut = () => {
    setWalletPublicKey(null);
    setAuthToken(null);
  };

  useEffect(() => {
    if (authToken) {
      const decodedToken = jwt.decode(authToken) as jwt.JwtPayload;
      if (decodedToken.exp < Date.now() / 1000) {
        logOut();
      }
    }
  });

  const isSignedIn = authToken && walletPublicKey;

  const auth = {
    authToken,
    isSignedIn,
    logIn,
    logOut,
    setTwitterAccessToken,
    twitterAccessToken,
    walletPublicKey,
  };

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

type AuthContext = {
  authToken: string;
  isSignedIn: boolean;
  logIn: (walletPublicKey: string, authToken: string) => void;
  logOut: () => void;
  setTwitterAccessToken: (token: string) => void;
  twitterAccessToken: string;
  walletPublicKey: string;
};

function useAuth() {
  return useContext<AuthContext>(authContext);
}

export { useAuth, ProvideAuth };
