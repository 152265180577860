import { useMutation } from "react-query";
import { useAuth } from ".";
import { SolsteadsSDK, TwitterOAuthAccessTokenParams } from "../solsteads-sdk";

export function useRequestTwitterAccessToken() {
  const { authToken } = useAuth();
  const solsteadsSDK = new SolsteadsSDK({ authToken });

  return useMutation((requestBody: TwitterOAuthAccessTokenParams) => {
    return solsteadsSDK.requestTwitterAccessToken(requestBody);
  });
}
