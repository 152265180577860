import axios from "axios";

export function getClient(authToken: string, baseURL?: string) {
  const instance = axios.create({
    baseURL: baseURL,
    headers: {
      authorization: `Bearer ${authToken}`,
    },
  });
  instance.interceptors.response.use(
    (response) => response,
    function (err) {
      return Promise.reject(err.response.data);
    }
  );
  return instance;
}
