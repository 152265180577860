import { useMutation, useQueryClient } from "react-query";
import { useAuth } from ".";
import { SetWalletTwitterHandleParams, SolsteadsSDK } from "../solsteads-sdk";

export function useSetWalletTwitterHandle() {
  const queryClient = useQueryClient();
  const { authToken } = useAuth();
  const solsteadsSDK = new SolsteadsSDK({ authToken });

  return useMutation(
    (params: SetWalletTwitterHandleParams) => {
      return solsteadsSDK.setWalletTwitterHandle(params);
    },
    {
      onMutate: async (params: SetWalletTwitterHandleParams) => {
        const walletTwitterHandleQueryKey = [
          "wallet",
          params.walletPublicKey,
          "twitter-handle",
        ];
        await queryClient.cancelQueries(walletTwitterHandleQueryKey);
        const previousTwitterHandleData = queryClient.getQueryData(
          walletTwitterHandleQueryKey
        );
        queryClient.setQueryData(walletTwitterHandleQueryKey, {
          twitterHandle: params.twitterHandle,
        });
        return { previousTwitterHandleData };
      },
    }
  );
}
