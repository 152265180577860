import {
  Configuration,
  SolsteadsApi,
  Token,
  TokenIdentifiers,
} from "../../solsteads-api";

export function shortenPublicKey(publicKey: string, blockchain = "sol") {
  if (blockchain === "eth") {
    return `${publicKey.slice(0, 6)}...${publicKey.slice(-4)}`;
  } else {
    return `${publicKey.slice(0, 4)}...${publicKey.slice(-4)}`;
  }
}

export function convertTokenToTokenIdentifiersObject(
  token: Token
): TokenIdentifiers {
  return {
    blockchain: token.blockchain,
    address: token.address,
    id: token.id,
  };
}

export function generateTokenStringIdentifier(token: TokenIdentifiers): string {
  const identifierComponents = [token.blockchain, token.address, token.id];
  const truthyComponents = identifierComponents.filter((c) => !!c);
  return truthyComponents.join("::");
}

export function generateTokenOrderMap(
  galleryOrderSetting: TokenIdentifiers[]
): { [key: string]: number } {
  if (!galleryOrderSetting) {
    return {};
  }

  return galleryOrderSetting.reduce<{ [key: string]: number }>(
    (
      acc: { [key: string]: number },
      currentValue: TokenIdentifiers,
      currentIndex: number
    ) => {
      acc[generateTokenStringIdentifier(currentValue)] = currentIndex;
      return acc;
    },
    {}
  );
}

export function generateTokenFilterMap(
  galleryFilterSetting: TokenIdentifiers[]
): { [key: string]: boolean } {
  if (!galleryFilterSetting) {
    return {};
  }

  return galleryFilterSetting.reduce<{ [key: string]: boolean }>(
    (acc: { [key: string]: boolean }, currentValue: TokenIdentifiers) => {
      acc[generateTokenStringIdentifier(currentValue)] = true;
      return acc;
    },
    {}
  );
}

export function getSolsteadsApiClient(authToken?: string) {
  return new SolsteadsApi(
    new Configuration({ basePath: "/api", accessToken: authToken })
  );
}
