import { useMutation } from "react-query";
import { useAuth } from ".";
import { SolsteadsSDK } from "../solsteads-sdk";

export function useRequestTwitterRequestToken() {
  const { authToken } = useAuth();
  const solsteadsSDK = new SolsteadsSDK({ authToken });

  return useMutation(() => {
    return solsteadsSDK.requestTwitterRequestToken();
  });
}
