import { useMutation, useQueryClient } from "react-query";
import { useAuth } from ".";
import {
  DeleteWalletTwitterHandleParams,
  SolsteadsSDK,
} from "../solsteads-sdk";

export function useDeleteWalletTwitterHandle() {
  const queryClient = useQueryClient();
  const { authToken } = useAuth();
  const solsteadsSDK = new SolsteadsSDK({ authToken });

  return useMutation(
    (params: DeleteWalletTwitterHandleParams) => {
      return solsteadsSDK.deleteWalletTwitterHandle(params);
    },
    {
      onMutate: async (params: DeleteWalletTwitterHandleParams) => {
        const walletTwitterHandleQueryKey = [
          "wallet",
          params.walletPublicKey,
          "twitter-handle",
        ];
        await queryClient.cancelQueries(walletTwitterHandleQueryKey);
        const previousTwitterHandleData = queryClient.getQueryData(
          walletTwitterHandleQueryKey
        );
        queryClient.setQueryData(walletTwitterHandleQueryKey, undefined);
        return { previousTwitterHandleData };
      },
    }
  );
}
