import { AxiosResponse } from "axios";
import { SolsteadsSDK } from "..";

export type DeleteWalletTokenGalleryFrameParams = {
  walletAddress: string;
  tokenAddress: string;
};

export async function deleteWalletTokenGalleryFrame(
  this: SolsteadsSDK,
  params: DeleteWalletTokenGalleryFrameParams
): Promise<void> {
  const response = await this.client.delete<
    DeleteWalletTokenGalleryFrameParams,
    AxiosResponse<void>
  >(
    `/api/wallets/${params.walletAddress}/tokens/${params.tokenAddress}/gallery/settings/frame`
  );
  return response.data;
}

export type SetWalletTokenGalleryFrameParams = {
  walletAddress: string;
  tokenAddress: string;
  frameId: string;
};

export async function setWalletTokenGalleryFrame(
  this: SolsteadsSDK,
  params: SetWalletTokenGalleryFrameParams
): Promise<void> {
  const response = await this.client.put<
    Pick<SetWalletTokenGalleryFrameParams, "frameId">,
    AxiosResponse<void>
  >(
    `/api/wallets/${params.walletAddress}/tokens/${params.tokenAddress}/gallery/settings/frame`,
    { frameId: params.frameId }
  );
  return response.data;
}
