import { useQuery, UseQueryOptions } from "react-query";
import { getSolsteadsApiClient } from "../utils";

export function useGetTokenMetadata(
  params: { tokenAddress: string },
  options?: UseQueryOptions
) {
  const solsteadsApi = getSolsteadsApiClient();

  return useQuery(
    ["token", params.tokenAddress, "metadata"],
    async () => {
      try {
        const response = await solsteadsApi.getTokenMetadata(
          params.tokenAddress
        );
        return response.data.metadata;
      } catch (err) {
        return undefined;
      }
    },
    {
      ...options,
      staleTime: Infinity,
      retry: true,
    }
  );
}
