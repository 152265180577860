import { useQuery } from "react-query";
import { useAuth } from ".";
import { GetWalletTwitterHandleParams, SolsteadsSDK } from "../solsteads-sdk";

export function useGetWalletTwitterHandle(
  params: GetWalletTwitterHandleParams
) {
  const { authToken } = useAuth();
  const solsteadsSDK = new SolsteadsSDK({ authToken });

  return useQuery(["wallet", params.walletPublicKey, "twitter-handle"], () => {
    return solsteadsSDK.getWalletTwitterHandle(params);
  });
}
